import { loadMercadoPago } from "@mercadopago/sdk-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const publicKey = nuxtApp.$config.public.mercadopago.publicKey;
  const options = nuxtApp.$config.public.mercadopago.options;

  if (!publicKey) {
    console.warn("No public key provided for MercadoPago.");
    return;
  }

  try {
    await loadMercadoPago();
    const mercadoPagoInstance = new window.MercadoPago(publicKey, options);

    nuxtApp.provide("mercadopago", mercadoPagoInstance);
  } catch (error) {
    console.error("Failed to initialize MercadoPago", error);
  }
});
